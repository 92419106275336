<template>
  <div id="testSolution">
    <el-dialog
      :title="testSolutionFormTitle"
      width="1200px"
      :visible.sync="testSolutionDialogVisible"
      :close-on-click-modal="false"
      @close="testSolutionDialogClose"
    >
      <el-form
        ref="testSolutionFormRef"
        :model="testSolutionForm"
        :rules="testSolutionFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="testSolutionForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配制量" prop="preparationAmount">
              <el-input v-model="testSolutionForm.preparationAmount" placeholder="请输入配制量" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="配制批号" prop="preparationBatch">
              <el-input v-model="testSolutionForm.preparationBatch" placeholder="请输入配制批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="testSolutionForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
           <el-col :span="12">
            <el-form-item label="配制日期" prop="preparationDate">
              <el-date-picker v-model="testSolutionForm.preparationDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期" prop="validPeriod">
              <el-date-picker v-model="testSolutionForm.validPeriod" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="所用试剂名称及入库编号" prop="reagentNameNo">
              <el-input
                v-model="testSolutionForm.reagentNameNo"
                placeholder="请输入所用试剂名称及入库编号"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="配制过程" prop="preparationProcess">
              <el-input
                v-model="testSolutionForm.preparationProcess"
                placeholder="请输入配制过程"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="配制人" prop="preparer">
              <el-input v-model="testSolutionForm.preparer" placeholder="请输入配制人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="testSolutionForm.reviewer" placeholder="请输入复核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="贮存条件" prop="storageConditions">
              <el-input v-model="testSolutionForm.storageConditions" placeholder="请输入贮存条件" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="testSolutionForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="testSolutionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="testSolutionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备编号">
        <el-input v-model="searchForm.deviceNo" placeholder="请输入设备编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="testSolutionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="preparationAmount" label="配制量" />
      <el-table-column prop="preparationBatch" label="配置日期" />
      <el-table-column prop="spec" label="溶剂名称" />
      <el-table-column prop="preparationDate" label="生产厂家" />
      <el-table-column prop="remarks" label="溶剂批号" />
      <el-table-column prop="reagentNameNo" label="溶剂浓度" />
      <el-table-column prop="preparationProcess" label="溶剂数量" />
      <el-table-column prop="preparer" label="加入纯化水量" />
      <el-table-column prop="storageConditions" label="配置后溶液数量" />
      <el-table-column prop="remarks" label="有效期" />
      <el-table-column prop="configurator" label="配置人" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="testSolutionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addTestSolution, deleteTestSolution, updateTestSolution, selectTestSolutionInfo, selectTestSolutionList } from '@/api/quality/testSolution'

export default {
  data () {
    return {
      testSolutionDialogVisible: false,
      testSolutionFormTitle: '',
      testSolutionForm: {
        id: '',
        name: '',
        preparationAmount: '',
        preparationBatch: '',
        spec: '',
        preparationDate: '',
        validPeriod: '',
        reagentNameNo: '',
        preparationProcess: '',
        preparer: '',
        reviewer: '',
        storageConditions: '',
        remarks: ''
      },
      testSolutionFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      testSolutionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceNo: ''
      }
    }
  },
  created () {
    selectTestSolutionList(this.searchForm).then(res => {
      this.testSolutionPage = res
    })
  },
  methods: {
    testSolutionDialogClose () {
      this.$refs.testSolutionFormRef.resetFields()
      this.testSolutionForm.id = ''
    },
    testSolutionFormSubmit () {
      if (this.testSolutionFormTitle === '试液、指示液、缓冲溶液配制记录') {
        this.testSolutionDialogVisible = false
        return
      }
      this.$refs.testSolutionFormRef.validate(async valid => {
        if (valid) {
          if (this.testSolutionFormTitle === '新增试液、指示液、缓冲溶液配制记录') {
            await addTestSolution(this.testSolutionForm)
          } else if (this.testSolutionFormTitle === '修改试液、指示液、缓冲溶液配制记录') {
            await updateTestSolution(this.testSolutionForm)
          }
          this.testSolutionPage = await selectTestSolutionList(this.searchForm)
          this.testSolutionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.testSolutionFormTitle = '新增试液、指示液、缓冲溶液配制记录'
      this.testSolutionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteTestSolution(row.id)
        if (this.testSolutionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.testSolutionPage = await selectTestSolutionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.testSolutionFormTitle = '修改试液、指示液、缓冲溶液配制记录'
      this.testSolutionDialogVisible = true
      this.selectTestSolutionById(row.id)
    },
    handleInfo (index, row) {
      this.testSolutionFormTitle = '试液、指示液、缓冲溶液配制记录详情'
      this.testSolutionDialogVisible = true
      this.selectTestSolutionById(row.id)
    },
    selectTestSolutionById (id) {
      selectTestSolutionInfo(id).then(res => {
        this.testSolutionForm.id = res.id
        this.testSolutionForm.name = res.name
        this.testSolutionForm.preparationAmount = res.preparationAmount
        this.testSolutionForm.preparationBatch = res.preparationBatch
        this.testSolutionForm.spec = res.spec
        this.testSolutionForm.preparationDate = res.preparationDate
        this.testSolutionForm.remarks = res.remarks
        this.testSolutionForm.reagentNameNo = res.reagentNameNo
        this.testSolutionForm.preparationProcess = res.preparationProcess
        this.testSolutionForm.preparer = res.preparer
        this.testSolutionForm.reviewer = res.reviewer
        this.testSolutionForm.storageConditions = res.storageConditions
        this.testSolutionForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectTestSolutionList(this.searchForm).then(res => {
        this.testSolutionPage= res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectTestSolutionList(this.searchForm).then(res => {
        this.testSolutionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectTestSolutionList(this.searchForm).then(res => {
        this.testSolutionPage = res
      })
    }
  }
}
</script>

<style>
</style>
